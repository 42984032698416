function showToast(data) {
    let _id = getTimestamp();

    iziToast.show({
        id: _id,
        color: data.color,
        title: data.title,
        icon: data.icon,
        position: 'topRight',
        theme: "light",
        timeout: data.progressBar ? 5000 : 0,
        progressBar: data.progressBar,
        close: data.close ? data.close : false,
        layout: 2
    });

    return _id;
}

function closeToast(id) {
    iziToast.hide({}, $(`#${id}`)[0]);
}